<template>
  <base-page
    :title="$t('gatewayManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.gatewayGetList"
      :defaultParams="params"
      :columns="columns"
      :multiple="true"
      :highlightCurrentRow="false"
      row-name="account"
      row-key="account"
      v-model="tableRows"
    >
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <!-- lang:重置密码 -->
        <el-button
          v-if="$page.showBtn('GATEWAY_EQUIPMENT_RESET')"
          type="text"
          @click="editMethod(row)"
        >
          {{ $t('functionEnum.GATEWAY_EQUIPMENT_RESET') }}
        </el-button>
      </template>
    </table-list>
    <!-- 详情 -->
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <!-- 操作日志 -->
    <dialog-table-list
      ref="dialogTableList"
      :title="$t('gatewayManage.operateLog')"
      v-if="showDialogTableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.gatewayFind"
      :formData="dialogFormData"
      :columns="dialogColumns"
      :config="dialogConfig"
      :showConfirm="false"
      @cancel="showDialogTableList = false"
      @search="dialogSearch"
    />
  </base-page>
</template>

<script>
import Detail from './detail'
import { importFile } from '@/utils/ajax'

export default {
  components: {
    Detail,
  },
  data() {
    const self = this
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('gatewayManage.searchHolder'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRows: [],
      tableRow: null,
      params: {},
      columns: [
        // lang:网关ID
        {
          label: this.$t('gatewayManage.account'),
          prop: 'account',
        },
        // lang:当前登录状态
        {
          label: this.$t('gatewayManage.status'),
          prop: 'status',
        },
        // lang:IP地址
        {
          label: this.$t('gatewayManage.ipAddress'),
          prop: 'ipAddress',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '150px',
        },
      ],
      // 表单页
      showDetail: false,
      // 操作日志
      showDialogTableList: false,
      dialogFormData: {},
      dialogConfig: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 12,
          tagProps: {
            placeholder: this.$t('gatewayManage.gatewayId'),
          },
        },
        {
          tag: 'el-select',
          label: this.$t('gatewayManage.type'),
          prop: 'type',
          span: 12,
          tagProps: {
            options: self.$dict.networkLogType(),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 12,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      dialogColumns: [
        // lang:网关ID
        {
          label: this.$t('gatewayManage.gatewayId'),
          prop: 'gatewayId',
        },
        // lang:mac地址
        {
          label: this.$t('gatewayManage.mac'),
          prop: 'mac',
        },
        // lang:类型
        {
          label: this.$t('gatewayManage.type'),
          prop: 'type',
          callback(row) {
            return self.$util.listToObj(self.$dict.networkLogType())[row.type]
          },
        },
        // lang:公司名称
        {
          label: this.$t('gatewayManage.companyName'),
          prop: 'companyName',
        },
        // lang:账号名称
        {
          label: this.$t('gatewayManage.accountName'),
          prop: 'accountName',
        },
        // lang:修改时间
        {
          label: this.$t('gatewayManage.updateTime'),
          prop: 'updateTime',
          callback(row) {
            return self.$util.format(row.updateTime, 'yyyy-MM-dd hh:mm:ss')
          },
        },
      ],
    }
  },
  methods: {
    // 弹出表格搜索
    dialogSearch() {
      this.$refs.dialogTableList.searchList(this.dialogFormData)
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    //操作
    editMethod(row) {
      this.tableRow = row
      this.$store.commit('updateCmd', 'resetPassword')
      this.$store.commit(
        'updateDialogTitle',
        this.$t('functionEnum.GATEWAY_EQUIPMENT_RESET')
      )
      this.showDetail = true
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      // 操作日志
      if (method === 'operateLog') {
        this.showDialogTableList = true
        return
      }
      if (!this.tableRows.length) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'export':
          this.multipleExport()
          break
        default: // do something
      }
    },
    // 多个导出
    multipleExport() {
      const ids = this.tableRows.map((item) => item.account)
      this.exportMethod(ids)
    },
    // 导出
    exportMethod(ids) {
      const params = {
        qrcodeSize: 170,
        logoSize: 10,
        fontWidth: 12,
        clo: 3,
        pageCount: 3,
        accountIds: ids,
      }
      importFile({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.gatewayExportGateway,
        // lang:网关设备二维码
        name: this.$t('gatewayManage.code'),
        type: '.docx',
        data: params,
      })
    },
  },
}
</script>