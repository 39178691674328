<template>
  <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  />
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      formData: {
        account: this.tableRow.account,
      },
      config: [
        // lang:网关ID
        {
          label: this.$t('gatewayManage.account'),
          prop: 'account',
          rules: {
            noNull: true,
          },
          tagProps: {
            disabled: true,
          },
        },
        // lang:新密码
        {
          label: this.$t('gatewayManage.password'),
          prop: 'password',
          rules: {
            noNull: true,
          },
          tagProps: {
            showPassword: true,
          },
        },
      ],
    }
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.gatewayResetPassword,
        data: {
          account: this.tableRow.account,
          password: this.formData.password,
        },
      }).then(() => {
        // lang:重置成功
        this.$element.showMsg(this.$t('base.resetSuccess'), 'success')
        this.$emit('closePage', 'resetPassword')
      })
    },
  },
}
</script>